import { createSelector } from 'reselect';
import { userSelector } from './authSelectors';

export const qdeckUsersSelector = (state) => state?.user?.qdeckUsers || [];

export const qdeckUserEmailNameMapSelector = createSelector([qdeckUsersSelector], (qdeckUsers) =>
  qdeckUsers.reduce((map, user) => ({ ...map, [user.email]: `${user.firstname} ${user.lastname}` }), {
    placeholder: ''
  })
);

export const bookkeepersSelector = (state) => state?.user?.bookkeepers || [];

export const usersLoadingSelector = (state) => state?.user?.loading;

export const isUserQDeckUserSelector = createSelector([qdeckUsersSelector, userSelector], (qdeckUsers, user) =>
  qdeckUsers.some((qdeckUser) => qdeckUser.email === user.email)
);

export const usersErrorMessageSelector = (state) => state?.user?.errorMsg;
