import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import taskListReducer from './reducers/taskListReducer';
import taskDetailReducer from './reducers/taskDetailReducer';
import { createSocketMiddleware } from '../utils/socket';
import userReducer from './reducers/userReducer';
import formUploadReducer from './reducers/formUploadReducer';

const reducer = {
  auth: authReducer,
  user: userReducer,
  taskList: taskListReducer,
  taskDetail: taskDetailReducer,
  formUpload: formUploadReducer
};
const preloadedState = {};
const middleware = [thunk, createSocketMiddleware()];

const store = configureStore({ reducer, preloadedState, middleware });

export default store;
