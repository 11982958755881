import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAuthenticatedSelector } from '../../selectors/authSelectors';
import { isUserQDeckUserSelector, usersLoadingSelector } from '../../selectors/userSelectors';

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isUserQDeckUser,
  areQDeckUsersLoading,
  ...routeProps
}) => (
  <Route
    {...routeProps}
    render={(props) =>
      (() => {
        if (!isAuthenticated) {
          return <Redirect to='/' />;
        }

        if (!areQDeckUsersLoading && !isUserQDeckUser && routeProps.path !== '/unauthorized') {
          return <Redirect to='/unauthorized' />;
        }

        return <Component {...props} />;
      })()
    }
  />
);

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  isUserQDeckUser: isUserQDeckUserSelector(state),
  areQDeckUsersLoading: usersLoadingSelector(state)
});

export default connect(mapStateToProps)(PrivateRoute);
