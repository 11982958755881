import _ from 'lodash';
import { TASK_LIST } from '../../actions/types';
import { ALL_STAGES, CURRENT_TAX_YEAR, DEFAULT_STAGES } from '../../global/Environment';

const initialState = {
  tasks: null,
  stageCounts: null,
  substageCounts: null,
  loading: false,
  successMsg: '',
  errorMsg: '',
  taxYear: CURRENT_TAX_YEAR,
  stages: DEFAULT_STAGES,
  showAllReturns: false,
  stageFilter: null,
  substageFilter: null,
  searchFilter: '',
  assigneeFilter: '',
  page: 0,
  order: null,
  bulkEditMode: null,
  bulkEditTasks: [],
  pendingReturnDownloadUsers: []
};

export default function taskListReducer(state = initialState, action) {
  switch (action.type) {
    case TASK_LIST.SET_TASKS:
      return {
        ...state,
        tasks: action.payload
      };
    case TASK_LIST.SET_STAGE_COUNTS:
      return {
        ...state,
        stageCounts: action.payload
      };
    case TASK_LIST.SET_SUBSTAGE_COUNTS:
      return {
        ...state,
        substageCounts: action.payload
      };
    case TASK_LIST.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case TASK_LIST.SET_RES_MSG:
      return {
        ...state,
        successMsg: action.payload.success,
        errorMsg: action.payload.error
      };
    case TASK_LIST.SET_YEAR:
      return {
        ...state,
        taxYear: action.payload
      };
    case TASK_LIST.SET_SHOW_ALL:
      return {
        ...state,
        showAllReturns: action.payload
      };
    case TASK_LIST.SET_STAGE_LIST:
      return {
        ...state,
        stages: action.payload
      };
    case TASK_LIST.SET_STAGE_FILTER:
      return {
        ...state,
        stageFilter: action.payload
      };
    case TASK_LIST.SET_SUBSTAGE_FILTER:
      return {
        ...state,
        substageFilter: action.payload
      };
    case TASK_LIST.SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.payload
      };
    case TASK_LIST.SET_ASSIGNEE:
      return {
        ...state,
        assigneeFilter: action.payload
      };
    case TASK_LIST.SET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case TASK_LIST.SET_ORDER:
      return {
        ...state,
        order: action.payload
      };
    case TASK_LIST.SET_BULK_EDIT_MODE:
      return {
        ...state,
        bulkEditMode: action.payload
      };
    case TASK_LIST.SET_BULK_EDIT_TASKS:
      return {
        ...state,
        bulkEditTasks: action.payload
      };
    case TASK_LIST.RESET_FILTERS:
      return {
        ...state,
        ...(action.payload
          ? {
              showAllReturns: true,
              stages: ALL_STAGES
            }
          : {}),
        stageFilter: null,
        searchFilter: ''
      };
    case TASK_LIST.ADD_PENDING_RETURN_DOWNLOAD_USER:
      return {
        ...state,
        pendingReturnDownloadUsers: [...state.pendingReturnDownloadUsers, action.payload]
      };
    case TASK_LIST.REMOVE_PENDING_RETURN_DOWNLOAD_USER:
      return {
        ...state,
        pendingReturnDownloadUsers: _.without(state.pendingReturnDownloadUsers, action.payload)
      };
    default:
      return state;
  }
}
