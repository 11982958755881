import { USERS } from './types';

export const setUsers = (users) => {
  return {
    type: USERS.SET_USERS,
    payload: users
  };
};

export const setBookkeepers = (bookkeepers) => {
  return {
    type: USERS.SET_BOOKKEEPERS,
    payload: bookkeepers
  };
};

export const setUsersLoading = (loading) => {
  return {
    type: USERS.SET_LOADING,
    payload: loading
  };
};

export const setUsersErrorMessage = (message) => {
  return {
    type: USERS.SET_ERROR_MESSAGE,
    payload: message
  };
};
