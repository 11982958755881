import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import history from '../../keeperHistory';
import { authErrorSelector, isAuthenticatedSelector } from '../../selectors/authSelectors';
import { signInGoogle } from '../../services/authService';
import './Login.scss';

const Login = ({ isAuthenticated, authError, signInGoogle }) => {
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/task-list');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // show error msg
  }, [authError]);

  const onLoginSuccess = (user) => {
    signInGoogle({ user });
  };

  const onLoginFailure = (err) => {
    // set auth error message
  };

  return (
    <div className='login'>
      <p className='title'>Sign in to Google to use QDeck</p>
      <GoogleLogin onSuccess={(user) => onLoginSuccess(user)} onError={(err) => onLoginFailure(err)} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  authError: authErrorSelector(state)
});

const mapDispatchToProps = {
  signInGoogle
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
