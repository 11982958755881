import _ from 'lodash';
import { createSelector } from 'reselect';

export const tasksSelector = (state) => _.get(state, ['taskList', 'tasks']);
export const stageCountsSelector = (state) => _.get(state, ['taskList', 'stageCounts']);
export const substageCountsSelector = (state) => _.get(state, ['taskList', 'substageCounts']);
export const tasksLoadingSelector = (state) => _.get(state, ['taskList', 'loading']);
export const taskListSuccessMsgSelector = (state) => _.get(state, ['taskList', 'successMsg']);
export const taskListErrorMsgSelector = (state) => _.get(state, ['taskList', 'errorMsg']);
export const yearSelector = (state) => _.get(state, ['taskList', 'taxYear']);
export const showAllReturnsSelector = (state) => _.get(state, ['taskList', 'showAllReturns']);
export const stageListSelector = (state) => _.get(state, ['taskList', 'stages']);
export const stageFilterSelector = (state) => _.get(state, ['taskList', 'stageFilter']);
export const substageFilterSelector = (state) => _.get(state, ['taskList', 'substageFilter']);
export const assigneeFilterSelector = (state) => _.get(state, ['taskList', 'assigneeFilter']);
export const taskListSearchFilterSelector = (state) => _.get(state, ['taskList', 'searchFilter']);
export const taskListPageSelector = (state) => _.get(state, ['taskList', 'page']);
export const taskListOrderSelector = (state) => _.get(state, ['taskList', 'order']);
export const bulkEditModeSelector = (state) => _.get(state, ['taskList', 'bulkEditMode']);
export const bulkEditTasksSelector = (state) => _.get(state, ['taskList', 'bulkEditTasks']);
export const pendingReturnDownloadUsersSelector = (state) => _.get(state, ['taskList', 'pendingReturnDownloadUsers']);

export const taskListResMsgSelector = createSelector(
  [taskListSuccessMsgSelector, taskListErrorMsgSelector],
  (successMsg, errorMsg) => {
    return errorMsg ? { type: 'error', msg: errorMsg } : { type: 'success', msg: successMsg };
  }
);
