import { useEffect } from 'react';

export const useEnsureQDeckUsers = (qdeckUsers, fetchQDeckUsers) => {
  useEffect(() => {
    if (qdeckUsers.length === 0) {
      fetchQDeckUsers();
    }
  }, [qdeckUsers.length, fetchQDeckUsers]);
};

export const useEnsureBookkeepers = (bookkeepers, fetchBookkeepers) => {
  useEffect(() => {
    if (bookkeepers.length === 0) {
      fetchBookkeepers();
    }
  }, [bookkeepers.length, fetchBookkeepers]);
};
