import { USERS } from '../../actions/types';

const initialState = {
  qdeckUsers: [],
  bookkeepers: [],
  loading: true,
  errorMsg: undefined
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USERS.SET_USERS:
      return {
        ...state,
        qdeckUsers: action.payload
      };
    case USERS.SET_BOOKKEEPERS:
      return {
        ...state,
        bookkeepers: action.payload
      };
    case USERS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case USERS.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.payload
      };
    default:
      return state;
  }
}
