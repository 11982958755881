import Loadable from 'react-loadable';
import Unauthorized from './components//Unauthorized/Unauthorized';
import LoadingPage from './components/Common/LoadingPage';
import Login from './components/Login/Login';
import UserManagement from './components/UserManagement/UserManagement';

const TaskList = Loadable({
  loader: () => import('./components/TaskList/TaskList'),
  loading: LoadingPage
});

const TaskDetail = Loadable({
  loader: () => import('./components/TaskDetail/TaskDetail'),
  loading: LoadingPage
});

const routes = [
  {
    path: '/',
    exact: true,
    component: Login,
    private: false
  },
  {
    path: '/task-list',
    exact: true,
    component: TaskList,
    private: true
  },
  {
    path: '/task-detail/:taskId',
    exact: true,
    component: TaskDetail,
    private: true
  },
  {
    path: '/manage-users',
    exact: true,
    component: UserManagement,
    private: true
  },
  {
    path: '/unauthorized',
    exact: true,
    component: Unauthorized,
    private: true
  }
];

export default routes;
