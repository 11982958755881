import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userSelector } from '../../selectors/authSelectors';
import {
  bookkeepersSelector,
  qdeckUsersSelector,
  usersErrorMessageSelector,
  usersLoadingSelector
} from '../../selectors/userSelectors';
import {
  Window,
  WindowHeader,
  WindowContent,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableDataCell,
  Hourglass,
  Button,
  Checkbox,
  Select
} from 'react95';
import { useEnsureBookkeepers, useEnsureQDeckUsers } from '../../utils/userUtils';
import { fetchBookkeepers, fetchQDeckUsers, updateQDeckUsers } from '../../services/usersService';
import _ from 'lodash';
import './UserManagement.scss';
import QDeckToolBar from '../Common/QDeckToolBar';
import history from '../../keeperHistory';
import ErrorModal from '../Common/ErrorModal';
import { setUsersErrorMessage } from '../../actions/userActions';

const USER_MANAGEMENT_COLUMNS = [
  {
    fieldName: 'email',
    displayName: 'Email',
    type: 'text'
  },
  {
    fieldName: 'firstname',
    displayName: 'First Name',
    type: 'text'
  },
  {
    fieldName: 'lastname',
    displayName: 'Last Name',
    type: 'text'
  },
  {
    fieldName: 'admin',
    displayName: 'Is Admin?',
    type: 'check'
  },
  {
    fieldName: 'eng',
    displayName: 'Is Engineer?',
    type: 'check'
  }
];

const DEFAULT_NEW_USER = {
  bookkeeperId: undefined,
  email: null,
  firstname: undefined,
  lastname: undefined,
  admin: 0,
  eng: 0,
  new: true
};

const UserManagement = ({
  user,
  qdeckUsers,
  bookkeepers,
  loading,
  error,
  fetchQDeckUsers,
  fetchBookkeepers,
  updateQDeckUsers,
  setErrorMessage
}) => {
  const [updatedQDeckUsers, setUpdatedQDeckUsers] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [invalidationText, setInvalidationText] = useState(undefined);

  const availableNewUsers = bookkeepers.filter(
    (bookkeeper) => !qdeckUsers.some((qdeckUser) => qdeckUser.email === bookkeeper.email)
  );

  useEffect(() => {
    setUpdatedQDeckUsers(_.sortBy(qdeckUsers, (user) => _.lowerCase(user.email)));
  }, [qdeckUsers]);

  useEffect(() => {
    const allUsersDefined = updatedQDeckUsers.every((user) => user.email);
    if (!allUsersDefined) {
      setInvalidationText('Please assign an email to all users');
      return;
    }
    setInvalidationText(undefined);
  }, [updatedQDeckUsers]);

  useEnsureQDeckUsers(qdeckUsers, fetchQDeckUsers);
  useEnsureBookkeepers(bookkeepers, fetchBookkeepers);

  const updateField = (targetIdx, field, value) => {
    setUpdatedQDeckUsers(
      updatedQDeckUsers.map((qdeckUser, idx) => (idx === targetIdx ? { ...qdeckUser, [field]: value } : qdeckUser))
    );
    setHasChanged(true);
  };

  const addUser = () => {
    setUpdatedQDeckUsers([{ ...DEFAULT_NEW_USER }, ...updatedQDeckUsers]);
    setHasChanged(true);
  };

  const updateNewUserEmail = (targetIdx, email) => {
    const bookkeeper = bookkeepers.find((bookkeeper) => bookkeeper.email === email);
    setUpdatedQDeckUsers(
      updatedQDeckUsers.map((qdeckUser, idx) =>
        idx === targetIdx ? { ...qdeckUser, ...bookkeeper, bookkeeper_id: bookkeeper.id } : qdeckUser
      )
    );
    setHasChanged(true);
  };

  const removeUser = (targetIdx) => {
    setUpdatedQDeckUsers([...updatedQDeckUsers.slice(0, targetIdx), ...updatedQDeckUsers.slice(targetIdx + 1)]);
    setHasChanged(true);
  };

  const submitChanges = () => {
    updateQDeckUsers(updatedQDeckUsers);
  };

  const onTaskListClick = useCallback(() => history.push('/task-list'), []);

  const onCloseErrorModal = () => setErrorMessage(undefined);

  const refreshBtn = {
    name: 'Refresh',
    onClick: fetchQDeckUsers
  };

  const taskListBtn = {
    name: 'Task List',
    onClick: onTaskListClick
  };

  return (
    <div className='user-management'>
      {error && <ErrorModal errorMsg={error} onClose={onCloseErrorModal} />}
      <QDeckToolBar type='task detail' menuItems={[taskListBtn, refreshBtn]} />
      <Window className='user-management-window'>
        <WindowHeader>
          <span>Manage Users</span>
          {loading && <Hourglass size={24} />}
        </WindowHeader>
        <WindowContent>
          <div className='user-management-actions'>
            <Button onClick={addUser}>Add User</Button>
            <Button disabled={!hasChanged || invalidationText} onClick={submitChanges}>
              Submit Changes
            </Button>
            <span>{invalidationText}</span>
          </div>
          <div className='user-management-table-container'>
            <Table>
              <TableHead>
                <TableRow>
                  {USER_MANAGEMENT_COLUMNS.map(({ displayName, fieldName }) => (
                    <TableHeadCell key={fieldName}>
                      <span>{displayName}</span>
                    </TableHeadCell>
                  ))}
                  <TableHeadCell>
                    <span>Remove</span>
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {updatedQDeckUsers.map((qdeckUser, idx) => (
                  <TableRow key={qdeckUser.email}>
                    {USER_MANAGEMENT_COLUMNS.map(({ fieldName, type }) => (
                      <TableDataCell key={fieldName}>
                        {type === 'check' && (
                          <Checkbox
                            checked={qdeckUser[fieldName]}
                            onClick={() => updateField(idx, fieldName, !qdeckUser[fieldName])}
                          />
                        )}
                        {(fieldName !== 'email' || !qdeckUser.new) && type !== 'check' && (
                          <div>{qdeckUser[fieldName]}</div>
                        )}
                        {fieldName === 'email' && qdeckUser.new && (
                          <Select
                            value={qdeckUser[fieldName]}
                            options={_.sortBy(availableNewUsers, (user) => _.lowerCase(user.email)).map((user) => ({
                              label: user.email,
                              value: user.email
                            }))}
                            width={250}
                            menuMaxHeight={300}
                            onChange={({ value: email }) => {
                              updateNewUserEmail(idx, email);
                            }}
                          />
                        )}
                      </TableDataCell>
                    ))}
                    <TableDataCell>
                      {user.email !== qdeckUser.email && (
                        <Button onClick={() => removeUser(idx)}>
                          <span className='remove-user-text'>X</span>
                        </Button>
                      )}
                    </TableDataCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </WindowContent>
      </Window>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: userSelector(state),
  qdeckUsers: qdeckUsersSelector(state),
  bookkeepers: bookkeepersSelector(state),
  loading: usersLoadingSelector(state),
  error: usersErrorMessageSelector(state)
});

const mapDispatchToProps = {
  fetchQDeckUsers,
  fetchBookkeepers,
  updateQDeckUsers,
  setErrorMessage: setUsersErrorMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
