import { SOCKET, FORM_UPLOAD } from './types';

export const connectSocket = () => {
  return {
    type: SOCKET.CONNECT
  };
};

export const disconnectSocket = () => {
  return {
    type: SOCKET.DISCONNECT
  };
};

export const subscribeTaskListSocket = () => {
  return {
    type: SOCKET.SUBSCRIBE_TASK_LIST
  };
};

export const subscribeTaskDetailSocket = (payload) => {
  return {
    type: SOCKET.SUBSCRIBE_TASK_DETAIL,
    payload
  };
};

export const setFormUploadCloudStatus = ({ recordId, status, error, userId }) => {
  return {
    type: FORM_UPLOAD.SET_CLOUD_STATUS,
    payload: { recordId, status, error, userId }
  };
};
