import React from 'react';
import { Window, WindowHeader, WindowContent, Frame, Button } from 'react95';
import './ErrorModal.scss';

const ErrorModal = ({ errorMsg, titleMsg, footerMsg, onClose }) => {
  return (
    <Window className='error-modal'>
      <WindowHeader className='error-modal-header'>
        <span>{titleMsg || 'Error occurred'}</span>
        <Button primary square size='sm' onClick={onClose}>
          X
        </Button>
      </WindowHeader>
      <WindowContent className='error-modal-body'>{errorMsg}</WindowContent>
      {footerMsg && (
        <Frame variant='status' className='error-modal-footer'>
          {footerMsg}
        </Frame>
      )}
    </Window>
  );
};

export default ErrorModal;
