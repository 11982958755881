import * as Sentry from '@sentry/react';
import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_LOGIN_CLIENT_ID } from '../src/global/Environment';
import App from './App';

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://7054ec3221354c59ef907d6b4cc984c6@o307744.ingest.us.sentry.io/4507579471626240',
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    debug: process.env.REACT_APP_ENV !== 'production'
  });
}

const root = createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENT_ID}>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </GoogleOAuthProvider>
);
