import io from 'socket.io-client';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { serverUrl } from '../global/Environment';
import { SOCKET, FORM_UPLOAD } from '../actions/types';
import {
  bulkEditModeSelector,
  pendingReturnDownloadUsersSelector,
  stageListSelector,
  substageFilterSelector,
  tasksSelector,
  yearSelector
} from '../selectors/taskListSelectors';
import { currentTaskSelector } from '../selectors/taskDetailSelectors';
import { getTaskDetailData } from '../services/taskDetailService';
import { getTaskListData } from '../services/taskListService';
import { qdeckUserEmailNameMapSelector } from '../selectors/userSelectors';
import { removePendingReturnDownloadUser } from '../actions/taskListActions';

export const createSocketMiddleware = () => (store) => {
  let socket = null;

  return (next) => (action) => {
    switch (action.type) {
      case SOCKET.CONNECT:
        if (socket) {
          socket.disconnect();
        }
        socket = createSocket(store);
        return;
      case SOCKET.DISCONNECT:
        if (socket) {
          socket.disconnect();
        }
        socket = null;
        return;
      case SOCKET.SUBSCRIBE_TASK_LIST:
        if (socket) {
          socket.emit('join task list');
        }
        return;
      case SOCKET.SUBSCRIBE_TASK_DETAIL:
        if (socket) {
          socket.emit('join task room', action.payload);
        }
        return;
      case FORM_UPLOAD.SET_CLOUD_STATUS:
        if (!socket) {
          socket = createSocket(store);
        }
        socket.emit('cloud upload result', action.payload);
        return;
      default:
        return next(action);
    }
  };
};

export const createSocket = (store) => {
  const socket = io(`${serverUrl()}qdeck`, {
    transports: ['websocket'],
    auth: (cb) => {
      cb({
        token: localStorage.getItem('KeeperToken')
      });
    }
  });

  socket.io.on('open', () => {
    console.log(`${new Date()}: socket connected`);
  });

  socket.io.on('reconnect', (attempt) => {
    const taskList = tasksSelector(store.getState());
    const currentTask = currentTaskSelector(store.getState());

    if (_.get(currentTask, 'id')) {
      store.dispatch(getTaskDetailData(currentTask.id, qdeckUserEmailNameMapSelector(store.getState())));
      socket.emit('join task room', currentTask.id);
    } else if (taskList) {
      store.dispatch(getTaskListData());
      socket.emit('join task list');
    }
  });

  socket.io.on('error', (e) => {
    console.error('socket encountered error: ', e.message, 'Closing socket');
  });

  socket.io.on('close', (reason) => {
    console.log(`${new Date()}: socket disconnected -- ${reason}`);
  });

  socket.on('connect_error', (error) => {
    console.error('There was an error connecting the socket: ', error.message);
    Sentry.captureException(error);
  });

  socket.on('message', (action) => {
    const payload = _.get(action, 'payload');
    const taskList = tasksSelector(store.getState());
    const taskListStages = stageListSelector(store.getState());
    const bulkEditMode = bulkEditModeSelector(store.getState());

    switch (action.type) {
      case 'TASK_UPDATE':
        const currentTask = currentTaskSelector(store.getState());
        const substageFilter = substageFilterSelector(store.getState());
        const updatedTaskId = _.get(payload, 'taskId');
        const updatedTaskField = _.get(payload, 'field');

        if (_.isEmpty(currentTask)) {
          if (
            taskList &&
            _.isNil(bulkEditMode) &&
            (updatedTaskField === 'assignee' ||
              updatedTaskField === 'stage' ||
              (updatedTaskField === 'ops_status' && substageFilter?.length) ||
              updatedTaskField === 'comments')
          ) {
            store.dispatch(getTaskListData());
          }
        } else if (currentTask.id === updatedTaskId) {
          store.dispatch(getTaskDetailData(currentTask.id, qdeckUserEmailNameMapSelector(store.getState())));
        }

        return;
      case 'TASK_CREATE':
        const taskListYear = yearSelector(store.getState());
        const newTaskYear = _.get(payload, 'year');
        const newTaskStage = _.get(payload, 'stage');
        if (
          taskList &&
          _.isNil(bulkEditMode) &&
          taskListYear === newTaskYear &&
          taskListStages.includes(newTaskStage)
        ) {
          store.dispatch(getTaskListData());
        }
        return;
      case 'GENERATE_TAX_RETURN':
        const userId = _.get(payload, 'user_id');
        const pdfUrl = _.get(payload, 'url');
        const pendingReturnDownloadUsers = pendingReturnDownloadUsersSelector(store.getState());
        if (!_.includes(pendingReturnDownloadUsers, userId)) {
          return;
        }
        store.dispatch(removePendingReturnDownloadUser(userId));
        window.open(pdfUrl, '_blank');
        return;
      default:
        return;
    }
  });

  return socket;
};
