import { AUTH } from './types';

export const setAuthUser = (payload) => {
  return {
    type: AUTH.SET_USER,
    payload
  };
};

export const setAuthErrorMsg = (payload) => {
  return {
    type: AUTH.SET_ERROR_MSG,
    payload
  };
};
