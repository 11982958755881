import _ from 'lodash';
import { AUTH } from '../../actions/types';

const initialState = {
  errorMsg: '',
  isAuthenticated: false,
  user: {}
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH.SET_USER:
      if (_.isEmpty(action.payload)) {
        return initialState;
      } else {
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload
        };
      }
    case AUTH.SET_ERROR_MSG:
      return {
        ...initialState,
        errorMsg: action.payload
      };
    default:
      return state;
  }
}
