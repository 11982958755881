import React from 'react';

const LoadingPage = ({ isLoading, pastDelay, error }) => {
  if (isLoading || pastDelay) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Sorry, there was a problem loading the page.</div>;
  }

  return null;
};

export default LoadingPage;
