import axios from 'axios';
import * as Sentry from '@sentry/react';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';
import { serverUrl } from '../global/Environment';
import { setAuthUser, setAuthErrorMsg } from '../actions/authActions';
import { connectSocket, disconnectSocket } from '../actions/socketActions';
import { identifyUser } from './analyticsService';

const baseUrl = serverUrl();

/**
 * @desc Social Login - Get User Token
 * @param {Object} obj Google Auth Token
 */
export const signInGoogle = (obj) => (dispatch) => {
  axios
    .post(`${baseUrl}api/web-ops/auth/signin-google`, obj)
    .then((response) => response.data)
    .then((res) => {
      const { status, data } = res;
      if (status === 'ok') {
        const { token } = data;

        // save token in localStorage and req headers
        localStorage.setItem('KeeperToken', token);
        setAuthToken(token);

        // decode token to get user data
        const decoded = jwt_decode(token);

        // set current user
        dispatch(setAuthUser(decoded));
        dispatch(connectSocket());
        identifyUser(decoded);
        Sentry.setUser({ id: decoded.id, email: decoded.email });
      } else {
        dispatch(setAuthErrorMsg('unauthorized login'));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const logout = () => (dispatch) => {
  // rm token from localStorage and future req headers
  localStorage.removeItem('KeeperToken');
  setAuthToken(false);
  dispatch(setAuthUser({}));
  dispatch(disconnectSocket());
  Sentry.setUser(null);
};
