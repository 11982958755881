import { FORM_UPLOAD } from '../../actions/types';

const initialState = {
  currentUploads: [],
  uploadStatus: null,
  keyedFiles: {},
  uploadError: null
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case FORM_UPLOAD.SET_CURRENT_UPLOADS: {
      return {
        ...state,
        currentUploads: action.payload
      };
    }
    case FORM_UPLOAD.SET_STATUS: {
      return {
        ...state,
        uploadStatus: action.payload
      };
    }
    case FORM_UPLOAD.SET_KEYED_FILES: {
      return {
        ...state,
        keyedFiles: action.payload
      };
    }
    case FORM_UPLOAD.SET_ERROR: {
      return {
        ...state,
        uploadError: action.payload
      };
    }
    default:
      return state;
  }
}
