import { TASK_DETAIL } from './types';

export const setTaskDetailLoading = (payload) => {
  return {
    type: TASK_DETAIL.SET_LOADING,
    payload
  };
};

export const setTaskDetailResMsg = (payload) => {
  const { success, error, title, footer } = payload;
  const resObj = {
    success: success || '',
    error: error || '',
    title: title || '',
    footer: footer || ''
  };
  return {
    type: TASK_DETAIL.SET_RES_MSG,
    payload: resObj
  };
};

export const setCurrentTask = (payload) => {
  return {
    type: TASK_DETAIL.SET_TASK,
    payload
  };
};

export const setFormUploads = (payload) => {
  return {
    type: TASK_DETAIL.SET_UPLOADS,
    payload
  };
};

export const setActivityLog = (payload) => {
  return {
    type: TASK_DETAIL.SET_ACTIVITY,
    payload
  };
};

export const setAskLog = (payload) => {
  return {
    type: TASK_DETAIL.SET_ASK_LOG,
    payload
  };
};

export const setComments = (payload) => {
  return {
    type: TASK_DETAIL.SET_COMMENTS,
    payload
  };
};

export const setTpInfoVisible = (payload) => {
  return {
    type: TASK_DETAIL.SET_TP_INFO_VISIBLE,
    payload
  };
};

export const setSubstagesVisible = (payload) => {
  return {
    type: TASK_DETAIL.SET_SUBSTAGES_VISIBLE,
    payload
  };
};

export const setOpsInfoVisible = (payload) => {
  return {
    type: TASK_DETAIL.SET_OPS_INFO_VISIBLE,
    payload
  };
};

export const setEscalationErrors = (payload) => {
  return {
    type: TASK_DETAIL.SET_ESC_ERRORS,
    payload
  };
};

export const setModalType = (payload) => {
  return {
    type: TASK_DETAIL.SET_MODAL_TYPE,
    payload
  };
};
