import { TASK_LIST } from './types';

export const setTaskListTasks = (payload) => {
  return {
    type: TASK_LIST.SET_TASKS,
    payload
  };
};

export const setTaskListStageCounts = (payload) => {
  return {
    type: TASK_LIST.SET_STAGE_COUNTS,
    payload
  };
};

export const setTaskListSubstageCounts = (payload) => {
  return {
    type: TASK_LIST.SET_SUBSTAGE_COUNTS,
    payload
  };
};

export const setTaskListLoading = (payload) => {
  return {
    type: TASK_LIST.SET_LOADING,
    payload
  };
};

export const setTaskListResMsg = (payload) => {
  const { success, error } = payload;
  const resObj = {
    success: success || '',
    error: error || ''
  };
  return {
    type: TASK_LIST.SET_RES_MSG,
    payload: resObj
  };
};

export const setTaskListYear = (payload) => {
  return {
    type: TASK_LIST.SET_YEAR,
    payload
  };
};

export const setTaskListShowAll = (payload) => {
  return {
    type: TASK_LIST.SET_SHOW_ALL,
    payload
  };
};

export const setTaskListStages = (payload) => {
  return {
    type: TASK_LIST.SET_STAGE_LIST,
    payload
  };
};

export const setTaskListStageFilter = (payload) => {
  return {
    type: TASK_LIST.SET_STAGE_FILTER,
    payload
  };
};

export const setTaskListSubstageFilter = (payload) => {
  return {
    type: TASK_LIST.SET_SUBSTAGE_FILTER,
    payload
  };
};

export const setTaskListAssignee = (payload) => {
  return {
    type: TASK_LIST.SET_ASSIGNEE,
    payload
  };
};

export const setTaskListPage = (payload) => {
  return {
    type: TASK_LIST.SET_PAGE,
    payload
  };
};

export const setTaskListOrder = (payload) => {
  return {
    type: TASK_LIST.SET_ORDER,
    payload
  };
};

export const setTaskListSearch = (payload) => {
  return {
    type: TASK_LIST.SET_SEARCH_FILTER,
    payload
  };
};

export const clearTaskListFilters = (showAllReturns) => {
  return {
    type: TASK_LIST.RESET_FILTERS,
    payload: showAllReturns
  };
};

export const setBulkEditMode = (payload) => {
  return {
    type: TASK_LIST.SET_BULK_EDIT_MODE,
    payload
  };
};

export const setBulkEditTasks = (payload) => {
  return {
    type: TASK_LIST.SET_BULK_EDIT_TASKS,
    payload
  };
};

export const addPendingReturnDownloadUser = (userId) => {
  return {
    type: TASK_LIST.ADD_PENDING_RETURN_DOWNLOAD_USER,
    payload: userId
  };
};

export const removePendingReturnDownloadUser = (userId) => {
  return {
    type: TASK_LIST.REMOVE_PENDING_RETURN_DOWNLOAD_USER,
    payload: userId
  };
};
