/**
 * @desc set user information for segment analytics
 */
export const identifyUser = (user) => {
  try {
    if (user && user.id) {
      const { id, firstName, lastName, email } = user;
      window.analytics.identify(`bk-${id}`, {
        bookkeeperFirstName: firstName,
        bookkeeperLastName: lastName,
        bookkeeperEmail: email
      });
    } else {
      clearAnalytics();
    }
  } catch (e) {
    console.log(e);
  }
};

const clearAnalytics = () => {
  try {
    window.analytics.reset();
  } catch (e) {
    console.log(e);
  }
};
