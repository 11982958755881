import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { Provider } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { styleReset } from 'react95';
import millenium from 'react95/dist/themes/millenium';
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';
import * as Sentry from '@sentry/react';
import jwt_decode from 'jwt-decode';
import store from './store/store';
import history from './keeperHistory';
import routes from './routes';
import PrivateRoute from './components/Common/PrivateRoute';
import PageNotFound from './components/Common/PageNotFound';
import { setAuthToken } from './utils';
import { setAuthUser } from './actions/authActions';
import { connectSocket } from './actions/socketActions';
import { logout } from './services/authService';
import { identifyUser } from './services/analyticsService';
import './index.scss';

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea, button {
    font-family: 'ms_sans_serif';
  }
`;

// authentication
if (localStorage.KeeperToken) {
  // check if logged in
  const token = localStorage.KeeperToken;
  const decoded = jwt_decode(token);
  store.dispatch(setAuthUser(decoded));

  // check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = '/';
  } else {
    setAuthToken(token);
    identifyUser(decoded);
    store.dispatch(connectSocket());
    Sentry.setUser({ id: decoded.id, email: decoded.email });
  }
}

// Add a response interceptor
axios.interceptors.response.use(function(response) {
  const msg = _.get(response, ['data', 'msg']);

  if (msg === 'Unauthorized Request') {
    store.dispatch(logout());
  } else {
    return response;
  }
});

const App = () => {
  const routeComponents = routes.map((routeProps) =>
    routeProps.private ? (
      <PrivateRoute key={routeProps.path} {...routeProps} />
    ) : (
      <Route key={routeProps.path} {...routeProps} />
    )
  );

  return (
    <Provider store={store}>
      <GlobalStyles />
      <ThemeProvider theme={millenium}>
        <Router history={history}>
          <Switch>
            {routeComponents}
            <Route exact component={PageNotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
