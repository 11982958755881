import { useEffect } from 'react';

/**
 * Call onOutsideClick upon click outside of element
 */
export const useOutsideClick = (elementRef, onOutsideClick) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        onOutsideClick(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elementRef, onOutsideClick]);
};
