import { setBookkeepers, setUsers, setUsersErrorMessage, setUsersLoading } from '../actions/userActions';
import { serverUrl } from '../global/Environment';
import axios from 'axios';

const baseUrl = serverUrl();

export const fetchQDeckUsers = () => async (dispatch) => {
  dispatch(setUsersLoading(true));
  try {
    const { status, data: users, msg } = (await axios.get(`${baseUrl}api/qdeck/users`)).data;
    if (status === 'ok') {
      dispatch(setUsers(users));
    } else {
      dispatch(setUsersErrorMessage(msg));
    }
  } catch (e) {
    dispatch(setUsersErrorMessage(e.response?.data?.msg || e.message || 'Error loading QDeck Users'));
  }
  dispatch(setUsersLoading(false));
};

export const updateQDeckUsers = (qdeckUsers) => async (dispatch) => {
  dispatch(setUsersLoading(true));
  try {
    const { status, msg } = (await axios.put(`${baseUrl}api/qdeck/users`, { users: qdeckUsers })).data;
    if (status === 'ok') {
      dispatch(fetchQDeckUsers());
    } else {
      dispatch(setUsersErrorMessage(msg));
    }
  } catch (e) {
    dispatch(setUsersErrorMessage(e.response?.data?.msg || e.message || 'Error updating QDeck Users'));
  }
  dispatch(setUsersLoading(false));
};

export const fetchBookkeepers = () => async (dispatch) => {
  try {
    const { status, data: bookkeepers, msg } = (await axios.get(`${baseUrl}api/qdeck/users/bookkeepers`)).data;
    if (status === 'ok') {
      dispatch(setBookkeepers(bookkeepers));
    } else {
      dispatch(setUsersErrorMessage(msg));
    }
  } catch (e) {
    dispatch(setUsersErrorMessage(e.response?.data?.msg || e.message || 'Error fetching bookkeepers'));
  }
};
