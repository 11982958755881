import React from 'react';
import ErrorModal from '../Common/ErrorModal';
import { isUserQDeckUserSelector, qdeckUsersSelector } from '../../selectors/userSelectors';
import { connect } from 'react-redux';
import history from '../../keeperHistory';
import { useEnsureQDeckUsers } from '../../utils/userUtils';
import { fetchQDeckUsers } from '../../services/usersService';

const Unauthorized = ({ qdeckUsers, isUserQDeckUser, fetchQDeckUsers }) => {
  useEnsureQDeckUsers(qdeckUsers, fetchQDeckUsers);
  if (isUserQDeckUser) {
    history.push('/task-list');
  }
  return (
    <>
      <ErrorModal errorMsg={'You are not authorized to use QDeck :('}></ErrorModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  qdeckUsers: qdeckUsersSelector(state),
  isUserQDeckUser: isUserQDeckUserSelector(state)
});

const mapDispatchToProps = {
  fetchQDeckUsers: fetchQDeckUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);
